// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const QUERY = gql`
  query DashboardCounterBlock($blockId: ID!) {
    counterBlockInfo: dashboardCounterBlock(blockId: $blockId)
  }
`;

export default QUERY;
